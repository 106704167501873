/*! _quickview.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Quickview extension 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Variables
1. Base styles
2. Header
3. Body
4. Footer
=============================================================================
***/

/* ==========================================================================
0. Variables
========================================================================== */
$quickview-shadow: 5px 0px 13px 3px rgba(0, 0, 0, 0.1) !default;
$quickview-divider: 1px solid $light-grey !default;

/* ==========================================================================
1. Base styles
========================================================================== */
.quickview {
    display: flex;
    flex-direction: column;
    background-color: $white;
    min-width: 300px;
    max-width: 300px;
    position: fixed;
    top: 0;
    bottom: 0;
    right: -310px;
    z-index: 999;
    transform: translateZ(0);
    transition: 0.3s ease;
    backface-visibility: hidden;
    perspective: 1000;
    will-change: transform;
    //active state
    &.is-active {
        right: 0;
        box-shadow: $quickview-shadow;
        //left position
        &.is-left {
            left: 0;
        }
    }
}

/* ==========================================================================
2. Header
========================================================================== */
.quickview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    min-height: 66px !important;
    border-bottom: $quickview-divider;
    //title
    .title {
        margin-bottom: 0;
    }
    //secondary header
    &.is-secondary {
        background: $secondary;
        p {
            color: $white;
            font-size: 1.4rem;
            font-weight: 400;
        }
        i {
            color: $white;
            font-size: 20px
        }
    }
    //close icon
    .cross-container {
        transition: transform 0.3s;
        &:hover {
            transform: rotate(90deg);
        }
    }
}

/* ==========================================================================
3. Body
========================================================================== */
.quickview-body {
    -webkit-box-flex: 1;
    flex: 1 1 0%;
}

/* ==========================================================================
4. Footer
========================================================================== */
.quickview-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    min-height: 4rem;
    background-color: $white;
    border-top: $quickview-divider;

    > * {
        margin: 0 0.4rem;
    }
}
